import THEMES_JSON from '@/assets/json/theme_variables.json';

export const THEME = Object.freeze({
  ABLIMMO: 'ablimmo',
  AETERRA: 'aeterra',
  ALIDES: 'alides',
  ALYCHLO: 'alychlo',
  ANTONISSEN: 'antonissen',
  APARTARCHITECTUUR: 'apartarchitectuur',
  ARCHITECTENBUREAUGOFFLO: 'architectenbureaugofflo',
  BATOBOUW: 'batobouw',
  BAU: 'bau',
  BAUWSTEEN: 'bauwsteen',
  BBREALESTATE: 'bbrealestate',
  BICON: 'bicon',
  BOUWPARTNERS: 'bouwpartners',
  BOWCO: 'bowco',
  BRODY: 'brody',
  BROOKVILLE: 'brookville',
  BURCOREALESTATE: 'burcorealestate',
  BVI: 'bvi',
  BWREALESTATE: 'bwrealestate',
  CAAAP: 'caaap',
  CANDOR: 'candor',
  CAPSTONEDEVELOPMENTGROUP: 'capstonedevelopmentgroup',
  CHATEAURESIDENTIES: 'chateauresidenties',
  CNEST: 'cnest',
  COMO: 'como',
  COMPAGNIEHETZOUTE: 'compagniehetzoute',
  COOLS: 'cools',
  CORDEEL: 'cordeel',
  CORDEELTEMSE: 'cordeeltemse',
  CORES: 'cores',
  COUSSEEBOUWENPROJECT: 'cousseebouwenproject',
  DCA: 'dca',
  DE_NIEUWE_DOKKEN: 'de-nieuwe-dokken',
  DE_VIJF: 'de-vijf',
  DEBLAERE: 'deblaere',
  DEFAULT: 'default',
  DETHIER: 'dethier',
  DHOORE_CONSTRUCT: 'dhoore-construct',
  DIFF: 'diff',
  DOMESTICNV: 'domesticnv',
  DOMUSCURA: 'domuscura',
  DUIN13: 'duin13',
  DUMOBIL: 'dumobil',
  DVLP: 'dvlp',
  DYLS: 'dyls',
  E_LBOUWPARTNERS: 'e-lbouwpartners',
  ENGAGE: 'engage',
  ERIBO: 'eribo',
  EROMBAUT: 'erombaut',
  FENIXCO: 'fenixco',
  FJORDRED: 'fjordred',
  FULLERSTONE: 'fullerstone',
  FURNIBO: 'furnibo',
  GIGANTES: 'gigantes',
  GLAM_LIVING: 'glamliving',
  GROEPVERELST: 'groepverelst',
  GROUPDENEVE: 'groupdeneve',
  GHELAMCO: 'ghelamco',
  HAVIS: 'havis',
  HEEM: 'heem',
  HEXAGON: 'hexagon',
  HOOYBERGHSBOUW: 'hooyberghsbouw',
  HOPROM: 'hoprom',
  HUYSMANBOUW: 'huysmanbouw',
  IMMOBELGROUP: 'immobelgroup',
  IMMOBELLUXEMBOURG: 'immobelluxembourg',
  IMMOFECC: 'immofecc',
  IMMOGRA: 'immogra',
  IMRODER: 'imroder',
  INTRABOUW: 'intrabouw',
  ION: 'ion',
  ION_DEVELOP_DIFFERENT: 'iondevelopdifferent',
  JANSSENB: 'janssenb',
  JENI: 'jeni',
  JOKKEBROK: 'jokkebrok',
  KINTPROJECTS: 'kintprojects',
  KOPSHOUT: 'kopshout',
  KUB: 'kub',
  KOLMONT: 'kolmont',
  LANDMARX: 'landmarx',
  LOYD: 'loyd',
  MAXHOME: 'maxhome',
  MENTRES: 'mentres',
  MGREALESTATE: 'mgrealestate',
  MMESTATEGROUP: 'mmestategroup',
  MMINVEST: 'mminvest',
  MOWK: 'mowk',
  N_E_W: 'n-e-w',
  ODEBRECHT: 'odebrecht',
  OLSTE: 'olste',
  OSMIUM76: 'osmium76',
  PLUYM_VANLOON: 'pluym-vanloon',
  POCPARTNERS: 'pocpartners',
  POLYFORM: 'polyform',
  POTRELL: 'potrell',
  PREFALUX: 'prefalux',
  PROJECTBOUW: 'projectbouw',
  PROLANBOUWPROMOTIE: 'prolanbouwpromotie',
  PROMIRISNUANCE: 'promirisnuance',
  QSINE: 'qsine',
  REDWOOD_VASTGOED: 'redwood-vastgoed',
  REXPROJECTS: 'rexprojects',
  RIETVELDPROJECTS: 'rietveldprojects',
  ROYAL_PROPERTIES: 'royal-properties',
  SANKT: 'sankt',
  SMARTHOME: 'smarthome',
  SPRINGVIS: 'springvis',
  STEENOVEN: 'steenoven',
  STRACO: 'straco',
  STUDENT_PLACES_GENT: 'studentplacesgent',
  THINKURBAN: 'thinkurban',
  THUZ: 'thuz',
  TRACOL: 'tracol',
  TRALUX: 'tralux',
  TRAVOO_BOUW: 'travoo-bouw',
  TRIPLE_LIVING: 'triple-living',
  UNIBRICKS: 'unibricks',
  URBICOON: 'urbicoon',
  VANDERSTRAETEN: 'vanderstraeten',
  VANPOPPEL: 'vanpoppel',
  VANTORNHAUT: 'vantornhaut',
  VANWELLEN: 'vanwellen',
  VASTIA: 'vastia',
  VERALTIS: 'veraltis',
  VERSTRAETE: 'verstraete',
  VESTRA: 'vestra',
  VOLUS: 'volus',
  WATERZANDE: 'waterzande',
  WEDEVELOP: 'wedevelop',
  WOONN: 'woonn',
  YNTRO: 'yntro',
  YOBO: 'yobo',
  YOUSTONE: 'youstone',
  YURE: 'yure',
  YXILON: 'yxilon',
  ZABRA: 'zabra',
});

export const THEMES = new Set(Object.values(THEME));

export const THEME_VARIABLES = [...THEMES].reduce((theme_variables, theme_name) => Object.assign(theme_variables,
  { [theme_name]: THEMES_JSON[theme_name] }), {});
